@import "../baxusStyles.scss";

.headerWrapper {
  align-items: center;
  height: 64px;
  // background-color: $body-bg;
  background: #525252;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0.8;

  &.hideHeader {
    display: none !important;
  }
  &.fullMenuOpacity {
    opacity: 1;
  }
  &.navbar {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 64px 1fr;
    .headerLogoWrapper {
      grid-row: 1;
      grid-column: 1;
      padding: 0;
      width: fit-content;
      z-index: 2;
    }
    .navbar-brand {
      margin-right: 0;
    }
  }
  .headerMenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    .navbar-nav {
      align-items: center;
      a,
      .nav-link {
        margin-right: 16px;
        color: $white;
        text-decoration: none;
      }
      a:hover,
      a.nav-link:focus {
        color: $white;
      }
      .nav-link.active {
        color: $white;
      }
    }
  }
  .mobileDropdown {
    width: 100%;
    height: 100vh;
    grid-column: 1;
    grid-row: 2;
    justify-self: end;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $body-bg;
    transition: height 2s cubic-bezier(0.19, 1, 0.22, 1);
    .navbar-nav {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 36px 0;
      .nav-item {
        display: flex;
        flex-grow: auto 1;
        cursor: pointer;
        width: 80%;
        padding: 8px 0;
        margin: 8px 0;
        border-bottom: 1px solid $baxus-grey-light;
        transition: opacity 0.3s ease-in-out;
        a {
          margin: 0;
          padding: 0;
          opacity: 0.7;
          width: 100%;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      max-width: 100vw;
    }
  }
}

.headerWrapper.navbar.hideHeader {
  display: none;
}

.headerWrapper.navbar {
  display: grid;
  grid-template-columns: 100%;
  .headerLogoWrapper {
    //TODO remove when menu is returned to homepage
    width: fit-content;
  }
  .navbar-toggler {
    grid-row: 1;
    grid-column: 1;
    justify-self: end;
    align-self: center;
    // source for mobile button animation
    // https://blog.bitsrc.io/animate-a-mobile-hamburger-bar-menu-using-css-and-just-a-hint-of-javascript-f31f928eb992
    .mobile-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 32px;
      height: 32px;
      background: transparent;
      border: none;
      padding: 0;
      right: 24px;
      top: 20px;
      .bar-one,
      .bar-two,
      .bar-three {
        width: 32px;
        height: 4px;
        background: $white;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
      }

      .bar-one {
        transform: rotate(0);
      }

      .bar-two {
        opacity: 1;
        transform: translateX(0);
      }

      .bar-three {
        transform: rotate(0);
      }
    }
    .open {
      .bar-one {
        transform: rotate(45deg);
      }

      .bar-two {
        opacity: 0;
        transform: translateX(20px);
      }

      .bar-three {
        transform: rotate(-45deg);
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    svg {
      height: 36px;
      width: 36px;
      stroke: $white;
    }
  }
  @media screen and (max-width: 768px) {
    .headerLogoWrapper {
      grid-row: 1;
      grid-column: 1;
      justify-self: center;
      padding: 0;
      right: 24px;
      top: 20px;
      .bar-one,
      .bar-two,
      .bar-three {
        width: 32px;
        height: 4px;
        background: $white;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
      }

      .bar-one {
        transform: rotate(0);
      }

      .bar-two {
        opacity: 1;
        transform: translateX(0);
      }

      .bar-three {
        transform: rotate(0);
      }
    }
    .open {
      .bar-one {
        transform: rotate(45deg);
      }

      .bar-two {
        opacity: 0;
        transform: translateX(20px);
      }

      .bar-three {
        transform: rotate(-45deg);
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    svg {
      height: 36px;
      width: 36px;
      stroke: $white;
    }
  }
}

.headerMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerWrapper .headerMenu .navbar-nav {
  align-items: center;
  a,
  .nav-link {
    margin-right: 16px;
    color: $white;
    text-decoration: none;
  }
  a:hover,
  a.nav-link:focus {
    color: $white;
  }
  .nav-link.active {
    color: $white;
  }
}
.btnSearch {
  background: #131416;
  box-shadow: 0px 33px 21px rgba(0, 0, 0, 0.25);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 140px;
  height: 47px;

  /* or 481% */

  text-align: center;

  color: #ffffff;
  margin-right: 123px;
}
.inputSearchField {
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  width: 400px;
}
.searchBoxHeader {
  position: relative;
}
.searchBoxChild {
  position: absolute;
  top: 100px;
  width: 572px;
  top: 57px;
  background: #202020;
}
.searchBoxContent h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #dd9c27;
  padding-top: 1rem;
  cursor: pointer;
}
.searchBoxParent {
  display: block;
}
@media screen and (max-width: 768px) {
  .searchBoxParent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 6rem;
  }
  .inputSearchField {
    width: 340px;
  }
  .searchBoxChild {
    z-index: 3;

    top: 57px;
    background: #202020;
    left: 4rem;
  }
}

@media screen and (max-width: 568px) {
  .inputSearchField {
    width: 280px;
  }
  .searchBoxChild {
    z-index: 3;

    width: 403px;
    top: 57px;
    background: #202020;
    left: 7rem;
  }
  .btnSearch {
    width: 100px;
    height: 40px;
  }
}
@media screen and (max-width: 468px) {
  .inputSearchField {
    width: 200px;
  }
  .btnSearch {
    width: 100px;
  }
}
