@import "@baxus/shared/src/baxusStyles.scss";

/* Featured asset row styles */

.featuredAssetListHeader {
  text-align: center;
  margin: 56px 0;
  font-weight: 300;
  @media screen and (max-device-width: 768px) {
    margin-bottom: 32px;
  }
}

.featuredAssetListWrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  justify-content: center;
}

.mobileFeaturedAssetListWrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  justify-content: center;
  height: 100%;
}

/* Featured asset styles */

.featuredAssetWrapper {
  grid-row: 1;
  width: 100%;
}

.mobileFeaturedAssetWrapper {
  grid-column: 1;
  z-index: 1;
  width: 100%;
  margin: 32px 0;
  padding-bottom: 16px;
}

.featuredAssetContentWrapper {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 640px;
  text-align: center;
  .featuredAssetBottleDescription {
    margin: 16px 0 0;
  }
  .featuredAssetImgWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    cursor: pointer;
    margin-top: 4rem;
    .featuredAssetImgBg {
      background-image: url(https://d241vrp4b4n7qa.cloudfront.net/Assets/featured_bottle_bg.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      justify-self: center;
      width: 100%;
      margin: 8% 0 5%;
      height: 80%;
      grid-row: 1;
      grid-column: 1;
      z-index: -1;
    }
    img {
      grid-row: 1;
      grid-column: 1;
      max-height: 560px;
      max-width: 90%;
      justify-self: center;
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .featuredAssetImgWrapper {
    min-height: 480px;
    p {
      margin-top: 16px;
      min-height: 50px;
    }
    .featuredAssetImgBg {
      margin: 5% 0;
      height: 100%;
      max-height: 500px;
    }
    img {
      margin: 0 auto;
    }
  }
}

.unselectedFeaturedAsset {
  .featuredAssetImgBg {
    display: none;
  }
  img {
    opacity: 0.1;
  }
}

.unselectedFeaturedAsset-1 {
  grid-column: 2;
}

.unselectedFeaturedAsset-2 {
  grid-column: 3;
}

/* Selected featured asset details */

.featuredAssetSelctedWrapper {
  grid-row: 1;
  grid-column: 2 / 4;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 42px;
}

.mobileAssetSelctedWrapper {
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 16px 0;
  .assetSelctedHeader {
    color: $baxus-gold;
  }
}

.featuredAssetSelectedEsc {
  width: 30px;
  height: 30px;
  justify-content: flex-start;
  align-self: flex-end;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
  }
}

.featuredAssetSelctedContent {
  margin: auto 0;
  font-weight: 300;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.featuredAssetSelectedBottleDetails {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-template-rows: repeat(2, 48px);
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }
  .featuredAssetSelectedBottleDetail {
    text-transform: capitalize;
    font-size: 14px;
    .featuredAssetSelectedBottleInfo {
      color: $baxus-gold;
    }
  }
}

.featuredAssetHighlightCTASection {
  display: flex;
  align-items: center;
  .featuredAssetHighlightCTASectionPrice {
    margin-bottom: 0;
    margin-right: 56px;
  }
  .featuredAssetHighlightCTAButton {
    @include transparentButton();
    width: 200px;
    margin-right: 24px;
    @media screen and (max-device-width: 768px) {
      margin-top: 16px;
      margin-right: 0;
    }
  }
  .featuredAssetHighlightCTAText {
    @include linkedText();
  }
}
.featuredAssetHighlightCTAButton:hover a {
  background-color: #dd9c27;
}
.backgroundImage {
  background: url("../../public/img/carouselg.png");
  height: auto;
  width: 100%;

  background-size: cover;
  padding: 12rem 9rem 12rem 9rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 991px) {
    padding: 9rem 0rem !important;
  }
  @media screen and (max-width: 768px) {
    padding: 9rem 0rem !important;
    display: none;
  }
}
