.faqCarouselWrapper1 {
  height: 300px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  margin-right: 24px;
}
.faqCarouselWrapper1 .carousel {
  grid-row: 1;
  grid-column: 2;
  margin-right: 20px;
  padding-bottom: 14px;
}
.faqCarouselWrapper1 .carousel .carousel-inner {
  overflow-y: none;
  min-height: 370px;
  max-height: 370px;
  overflow-y: auto;
}
.faqCarouselWrapper1 .carousel .carousel-inner .carousel-item p {
  margin-bottom: 2rem;
}

.carousel-item p {
  /* padding: 0 0 8px; */
  font-size: 12px;
}
.assetBtnCTA {
  margin-top: 2rem;
}
.assetPurchaseCTA {
  min-width: 180px;
  background: #131416;
  border: 1px solid #dd9c27;
  box-shadow: 0px 33px 21px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 45px;
}
.assetPurchaseCTA:hover {
  background-color: #dd9c27;
}
.assetPurchaseCTA a {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  /* identical to box height, or 385% */

  text-align: center;

  color: #ffffff;
}
.assetPurchaseCTA a:hover {
  background-color: #dd9c27;
}
.abvSection {
  display: flex;
  padding-bottom: 1rem;
}
.col-lg-6 {
  margin-top: 1rem;
}

imgSection p {
  font-size: 10px;
}
.fontSection {
  margin-bottom: 0;
  margin-right: 56px;
  font-size: 20px;
}

.faqControlsWrapper1 {
  display: flex;
  gap: 18px;
  position: relative;
  top: -12rem;
}

.faqCarouselWrapper1 .carousel .carousel-indicators button {
  position: relative;
  left: 0;
  bottom: 0;
}

.faqCarouselWrapper1 .carousel .carousel-indicators > button {
  background-color: #000000;
}
.faqCarouselWrapper1 .carousel .carousel-indicators button.active {
  background-color: #dd9c27;
}
.featuredAssetHighlightCTASection {
  padding-bottom: 1rem;
}
.imgSection {
  background: #1a1b1d;
  display: flex;
  justify-content: center;
  height: 300px;
  margin-top: 1rem;
  width: 362px;
}
.imgSection img {
  height: 350px;
  position: relative;
  margin-top: -1rem;
}

.ControlButton {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #fff;
}
.icon {
  color: #fff;
}
.btnSection1 {
  background: #131416;
  border: 1px solid #dd9c27;

  width: 200px;
  height: 50px;
}
.btnSection1:hover {
  background-color: #dd9c27;
  color: #000000;
}
.btnSection1 a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #ffffff !important;
  text-decoration: none;
}
.btnSection1 a:hover {
  background-color: #dd9c27;
  color: "#000" !important;
}
.featuredAssetImgWrapper1 h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;

  color: #dd9c27;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  /* justify-content: left !important; */
  padding: 0;
  margin-right: 10% !important;
  margin-bottom: -2rem !important;
  margin-left: 15%;
  list-style: none;
}
@media screen and (max-width: 1024px) {
  .imgSection {
    width: 100%;
  }
  .featuredAssetImgWrapper1 {
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .imgSection {
    width: 100%;
  }
  .featuredAssetImgWrapper1 {
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .mobileScreen {
    display: none;
  }

  .carousel-indicators {
    margin-bottom: 1rem !important;
    display: flex;
    justify-content: center !important;
  }
  .faqCarouselWrapper1 .carousel .carousel-inner {
    margin: 0;
    overflow-y: scroll;
  }
  .featuredAssetImgWrapper1 {
    padding-bottom: 2rem;
  }

  .faqCarouselWrapper1 .carousel {
    grid-column: 1;
    margin-right: 0;
    padding-bottom: 0px;
  }
  .faqCarouselWrapper1 .carousel .carousel-inner {
    margin: 16px 0;
  }

  .carousel-item {
    /* padding: 0 0 8px; */
    padding: 8px 24px;
    min-height: 340px;
    max-height: 740px;
    white-space: pre-line;
  }
  .faqControlsWrapper1 {
    position: relative;
    display: flex;
    justify-content: center;
    top: 0rem;
  }
  .imgSection {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .row {
    display: flex;
    justify-content: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 600px) {
  .featuredAssetImgWrapper1 h6 {
    margin-top: 1rem;
  }
}
