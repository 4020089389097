@import "@baxus/shared/src/baxusStyles.scss";

/*
  General Styles (see baxusStyles.scss for bootstrap overrides)
*/

.homepageWrapper {
  width: 100%;
  height: 100%;
}

.hp-sec {
  margin: 40px 0;
  height: 100vh;
  max-height: 680px;
  min-height: 500px;
  @media screen and (max-width: 1040px) {
    max-height: 600px;
    min-height: 500px;
  }
  @media screen and (max-width: 768px) {
    min-height: 100vh;
    max-height: none;
    height: fit-content;
  }
}

.sectionEndCTA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sectionEndCTAHeader {
    text-transform: capitalize;
  }
  .sectionEndCTAButton {
    @include transparentButton();
  }
}

.hpAssetEndCTA {
  margin-top: 32px;
}

.pageEndCTA {
  margin-top: 64px;
  .sectionEndCTAButton {
    margin: 16px 0 32px;
  }
}

/* age verification section */

.ageValidationModalContainer {
  .modal-content {
    padding: 64px;
    background-color: $body-bg;
    @media screen and (max-width: 768px) {
      padding: 8px;
    }
  }
}

.ageValidationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ageValidationHeader {
    text-transform: uppercase;
    padding: 0 16px;
  }
  .ageValidationCTAs {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .ageValidationCTA {
      min-width: 160px;
      margin: 0 16px;
      @include transparentButton();
    }
  }
  .ageValidationErrorWrapper {
    height: 20px;
    text-align: center;
    .ageValidationError {
      margin: 0;
      padding-top: 16px;
      color: $red;
    }
  }
  .ageValidationPrivacyAndRes {
    margin-top: 64px;
    font-size: 10px;
    text-align: center;
    a {
      color: $baxus-gold;
      &:hover {
        color: $white;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .ageValidationHeader {
      padding: 0;
      .ageValidationCTAs {
        flex-direction: column;
        justify-content: space-between;
        .ageValidationCTA {
          margin: 8px 0;
        }
      }
    }
  }
}

/* general services styles */

.servicesSubheader {
  color: $baxus-gold;
  font-weight: bold;
}

.servicesDescription {
  color: $white;
  font-weight: 300;
}

.servicesContent {
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
}

.servicesCTA {
  @include transparentButton();
}

/*
  Splash Section (/Zone)
*/

.splashWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: stretch;
  overflow-x: hidden;
  text-align: center;
  margin: 0;
  .splashVideo {
    grid-row: 1;
    grid-column: 1;
    z-index: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    min-height: 100vh;
  }
  .splashContent {
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    display: grid;
    grid-template-rows: 10% 90%;
    align-items: center;
    height: 100%;
    margin: 0 24px;
    .splashLogo {
      align-self: center;
      text-align: left;
    }
    .splashLogo1 {
      display: flex;
      justify-content: center;
    }
    .splashText {
      justify-self: center;
      margin: 0 16%;
      .splashHeader {
        color: #ffffff;
        text-transform: capitalize;
      }
      .splashSubheader {
        padding: 0 24px;
      }

      .splashCTA {
        margin-top: 24px;
        @include linkedText();
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .splashVideo {
    object-position: center;
  }
  .splashText {
    padding: 64px 0;
    justify-self: center;
    margin: 0 16%;
    .splashHeader {
      color: #ffffff;
      text-transform: capitalize;
    }
    .splashSubheader {
      padding: 0 24px;
    }
  }
}

/*
  Left Image Section
*/

.leftImgSecWrapper {
  display: grid;
  grid-template-columns: 45% 55%;
  .leftImgSecImgWrapper {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .leftImgSecContent {
    grid-row: 1;
    grid-column: 2;
    z-index: 1;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: left;
    padding-left: 20%;
  }
}

@media screen and (max-width: 768px) {
  .leftImgSecWrapper {
    grid-template-columns: 100%;
    margin: 24px 0;
    .leftImgSecImgWrapper {
      margin: 24px 0;
    }
    .leftImgSecContent {
      grid-row: 2;
      grid-column: 1;
      width: 100%;
      padding: 48px 0 24px;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

/*
  Right Image Section
*/

.rightImgSecWrapper {
  display: grid;
  grid-template-columns: 45% 55%;
  .rightImgSecOverflowBg {
    grid-column: 1;
    grid-row: 1;
    z-index: -1;
    height: 160%;
    margin: -100px 0 -100px 0;
    background-color: $baxus-grey-dark;
    background-position: center;
  }
  .rightImgSecImgWrapper {
    width: 100%;
    height: 100%;
    grid-column: 2;
    grid-row: 1;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rightImgSecContent {
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 24px;
  }
}

@media screen and (max-width: 768px) {
  .rightImgSecWrapper {
    grid-template-columns: 100%;
    margin: 24px 0;
    .rightImgSecImgWrapper {
      grid-column: 1;
      grid-row: 1;
      margin: 24px 0;
    }
    .rightImgSecContent {
      grid-row: 2;
      grid-column: 1;
      width: 100%;
      padding: 48px 0 24px;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

/* hp section cta styles*/
.HPFormWrapper {
  display: flex;
  flex-direction: column;
  .HPFormInput {
    padding: 12px 16px;
    border: 1px solid $baxus-gold;
    margin-bottom: 16px;
    color: $white;
    background-color: $body-bg;
    &:focus-visible {
      outline: $baxus-gold;
    }
  }
  .HPFormSuccess {
    color: $baxus-gold;
  }
  .HPFormError {
    color: $danger;
  }
}

/* section specific style */

.hp-sec .assetsWrapper {
  padding: 40px 0;
  display: grid;
}

.assets-sec {
  margin-bottom: 120px;
  .assetBGBox {
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
  }
}

.tokenization-sec {
  .leftSideBorderWrapper {
    grid-row: 1;
    grid-column: 2 / span 3;
    z-index: 1;
    border: 1px solid $baxus-gold;
    margin: 0 -10% 56px -300px;
    padding-left: 60%;
    padding-right: 40px;
  }
}

.tokenization-sec {
  .leftImgSecImgWrapper {
    z-index: 2;
    margin-top: 24px;
    width: 100%;
    height: 100%;
  }
}

.insure-sec {
  .rightImgSecOverflowBg {
    width: 150%;
  }
  @media screen and (max-width: 768px) {
    .rightImgSecOverflowBg {
      width: 110%;
    }
    .rightImgSecImgWrapper {
      padding-bottom: 64px;
    }
  }
}

.trade-sec {
  .rightImgSecOverflowBg {
    width: 110%;
    height: 120%;
  }
}

/* FAQ styles */

.faq-sec {
  margin-top: 80px;
  & > div {
    @media screen and (max-width: 768px) {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
.faqWrapper {
  display: grid;
  z-index: 2;
  grid-template-columns: 35% 65%;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.faqQuestionAreaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  margin-top: 32%;
}

.faqQuestionHeader {
  text-transform: capitalize;
  margin-bottom: 24px;
  min-height: 200px;
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    min-height: 128px;
  }
}

.faqQuestionSubHeader {
  font-weight: bold;
  color: $baxus-gold;
}

.faqControlsWrapper {
  display: flex;
  gap: 48px;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.faqControlButton {
  color: $white;
  background-color: transparent;
  border: none;
  padding: 0;
  svg {
    height: 40px;
    width: 40px;
  }
}

.faqCarouselWrapper {
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  margin-right: 24px;
  .carousel {
    grid-row: 1;
    grid-column: 2;
    margin-right: 20px;
    padding-bottom: 64px;
    .carousel-inner {
      overflow-y: scroll;
      min-height: 340px;
      // ie fix
      scrollbar-face-color: $baxus-gold;
      scrollbar-track-color: $baxus-grey-dark;
      // firefox fix
      scrollbar-width: thin;
      scrollbar-color: $baxus-gold $baxus-grey-dark;
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: $baxus-grey-dark;
        border: none;
        box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
        border: 3px solid $baxus-grey-dark;
        border-radius: 8px;
        background: $baxus-gold;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $baxus-gold;
      }
    }
    .carousel-indicators > button {
      background-color: #000000;
    }
    .carousel-indicators button.active {
      background-color: $baxus-gold;
    }
  }
  .carousel-item {
    padding: 8px 24px;
    min-height: 340px;
    max-height: 340px;
    white-space: pre-line;
  }
}

@media screen and (max-width: 768px) {
  .faqCarouselWrapper {
    margin: 0;
    .carousel {
      grid-column: 1;
      margin-right: 0;
      padding-bottom: 32px;
      .carousel-inner {
        margin: 16px 0;
        // ie fix
        scrollbar-face-color: $baxus-gold;
        scrollbar-track-color: $body-bg;
        // firefox fix
        scrollbar-color: $baxus-gold $body-bg;
        &::-webkit-scrollbar-track {
          background: $body-bg;
        }
      }
    }
    .carousel-item {
      padding: 0 0 8px;
    }
  }
}

.faqBGWrapper {
  height: 86%;
  width: 150%;
  grid-row: 1;
  grid-column: 2;
  justify-self: center;
  z-index: -1;
  background-color: $baxus-grey-dark;
  background-position: center;
  margin-left: -35%;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 768px) {
    grid-column: 1;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.faqBGBorder {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  height: 100%;
  justify-self: center;
  z-index: -2;
  border: 1px solid $baxus-gold;
  margin-left: 24%;
}
