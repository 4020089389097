@import "@baxus/shared/src/baxusStyles.scss";

/* Assets page styles*/

.assetsPageWrapper {
  margin: 0px 0;
  .assetsSplashWrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
  .featuredAssetListHeader {
    margin: 0 0 32px;
    font-weight: 600;
  }
  .featuredAssetListWrapper {
    margin-bottom: 24px;
  }
}

.assetsPageHeader {
  text-align: center;
  margin: 40px 0;
}

.assetsPageAllAssetsWrapper {
  margin: 80px 32px;
  display: grid;
  grid-template-rows: auto;
  row-gap: 124px;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
  justify-content: center;
  @media screen and (max-width: 1280px) {
    column-gap: 27px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1024px) {
    column-gap: 27px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 80px 42px;
  }
}

/* Asset seleceted modal styles */
.assetSelectedWrapper {
  background-color: $baxus-grey-dark;
  .assetSelectedBodyWrapper {
    display: flex;
    flex-direction: column;
    .assetSelectedBodyContentWrapper {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 40% 60%;
      column-gap: 16px;
      @media screen and (max-width: 768px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
      }
      .assetSelectedBodyImage {
        grid-column: 1;
        grid-row: 1;
        min-height: unset;
        .featuredAssetImgWrapper {
          img {
            z-index: 1;
          }
        }
      }
      .assetSelectedBodyContent {
        grid-column: 2;
        grid-row: 1;
        margin: 0;
        @media screen and (max-width: 768px) {
          grid-row: 2;
          grid-column: 1;
          padding-top: 32px;
        }
      }
    }
  }
}
// .filterIcon {
//   display: none;
// }
// @media screen and (max-width: 600px) {
//   .filterIcon {
//     display: block;
//   }
// }
// @media screen and (max-width: 600px) {
//   .filterSection {
//     display: none;
//   }
// }
