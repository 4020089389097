.filterSection h6 {
  margin-top: 3rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #dd9c27;
}

.checkBoxSection {
  display: flex;
  align-items: center;
  cursor: pointer;

  /* height: 20px; */
}
.checkBoxSection input {
  cursor: pointer;
}
.checkBoxSection label {
  cursor: pointer;
}
.checkBoxSection input[type="checkbox"]:checked + label {
  color: #dd9c27;
  font-style: normal;
  cursor: pointer;
}

.checkBoxSection input:checked {
  border: 1px solid #dd9c27;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #dd9c27;
  cursor: pointer;
}

.scrollBarDiv {
  overflow-y: scroll;
  height: 100px;
}
div ::-webkit-scrollbar {
  width: 1px;
  /* background: yellow; */
  border-radius: 12px;
  border: 1px solid #313131;
}
div ::-webkit-scrollbar-thumb:vertical {
  background: #dd9c27;
  box-shadow: 0px 33px 21px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  width: 24px;
}
div ::-webkit-scrollbar-button {
  height: 7px;
}

.checkBoxSection input[type="checkbox"] {
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #707070;
}
.checkBoxSection input:checked ~ .checked {
  background: #dd9c27;
}
.checkBoxSection h5 {
  font-size: 14px;
  padding-top: 0.5rem;
}
.filterSection1 {
  display: flex;
  align-items: center;
}
.filterSection1 h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;

  color: #dd9c27;
  padding-top: 0.5rem;
}
.checkBoxSection input[type="checkbox"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

.checkBoxSection input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.checkBoxSection input[type="checkbox"]:checked::before {
  transform: scale(1);
}
