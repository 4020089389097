.collectionBg {
  background: #dd9c27;
  margin-bottom: 3rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  height: 80%;
  /* background-image: url("./img/icons.png"); */
  background-image: url("../../../public/img/icons.png");
  /* margin-top: -100%; */
}
.collectionBg img {
  height: 540px;
  margin-top: -2rem;
}
.textHeader h1 {
  padding-top: 3rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 96px;
  line-height: 80px;
  /* identical to box height, or 83% */

  text-align: center;

  color: #ffffff;
}
.textHeader p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  padding-top: 1rem;
  line-height: 38px;
  /* or 211% */

  text-align: center;

  color: #ffffff;
  word-break: break-all;
  width: 1000px;
  padding-bottom: 7rem;
}

@media screen and (max-width: 991px) {
  .textHeader h1 {
    padding-top: 1.5rem;

    font-size: 47px;
    line-height: 80px;
    /* identical to box height, or 83% */

    text-align: center;

    color: #ffffff;
  }
  .textHeader p {
    font-size: 16px;
    width: 100%;
    padding-bottom: 4rem;
  }
}
