// Override default variables before the import
$body-bg: #131416;
$body-color: #ffffff;
$primary: #4a3e3e;
$white: #ffffff;
$baxus-gold: #dd9c27;
$baxus-grey-light: #707070;
$baxus-grey-dark: #1a1b1d;
$danger: #fd346e;

$font-family-sans-serif: Poppins;
$font-size-base: 1rem !default;
$h2-font-size: $font-size-base * 3 !default;
$headings-font-weight: 600 !default;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@mixin transparentButton {
  border: 1px solid $baxus-gold;
  color: $white;
  background-color: $body-bg;
  padding: 16px 24px;
  border-radius: 0;
  max-width: 250px;
  display: inline-block;
  box-shadow: 0px 33px 21px rgba(0, 0, 0, 0.25);
  &:hover {
    border: 1px solid $baxus-gold;
    color: $body-bg;
    background-color: $baxus-gold;
    a {
      color: $body-bg;
    }
  }
  a {
    color: $white;
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

@mixin linkedText {
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  color: $white;
  &:hover {
    color: $baxus-gold;
  }
  a {
    text-decoration: none;
    color: $white;
    &:hover {
      color: $baxus-gold;
    }
  }
  svg {
    height: 14px;
    width: 14px;
    padding-left: 4px;
    stroke: $white;
    stroke-width: 2px;
  }
}
