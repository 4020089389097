@import "@baxus/shared/dist/baxusStyles.scss";

html {
  // ie fix
  scrollbar-face-color: $baxus-gold;
  scrollbar-track-color: $body-bg;
  // firefox fix
  scrollbar-color: $baxus-gold $body-bg;
}

html::-webkit-scrollbar {
  width: 16px;
}

html::-webkit-scrollbar-track {
  background: $body-bg;
}

html::-webkit-scrollbar-thumb {
  border: 3px solid $body-bg;
  border-radius: 8px;
  background: $baxus-gold;
}

html::-webkit-scrollbar-thumb:hover {
  background: $baxus-gold;
}

#root {
  height: 100%;
  width: 100%;
  color: $white;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}

.pageWrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#main-content {
  flex: 1;
}

/* mailto link */
.mailtoLink {
  color: $baxus-gold;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    color: $white;
  }
}

/* Legal page pages */
.legalPageWrapper {
  margin-top: 100px;
}

.legalPageHeaderWrapper {
  margin-bottom: 48px;
  text-align: center;
}

.legalPageContent {
  font-size: 12px;
  .legalPageSectionHeader {
    text-transform: uppercase;
    color: $baxus-gold;
    margin: 8px 0;
  }
  .legalPageSectionSubheader {
    margin-left: 3%;
  }
  .legalPageSectionContent {
    margin-left: 3%;
  }
  .legalPageSectionContentBullet {
    margin-left: 8%;
  }
  .legalPageSectionBolded {
    font-weight: bold;
  }
  a {
    color: $baxus-gold;
    text-decoration: none;
    &:hover {
      color: $white;
    }
  }
}
