@import "../baxusStyles.scss";

.footerWrapper {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  background-color: $baxus-grey-dark;
  padding-top: 60px;
}

.footerTopSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .footerLogo {
    width: 20px;
    height: 20px;
  }

  .footerDetails {
    display: flex;
    justify-content: space-evenly;
    gap: 20%;
  }

  .footerDetailsSection {
    white-space: nowrap;
  }

  .footerDetailsColHeader {
    color: $white;
    font-weight: 600;
  }

  .footerDetailsContent {
    margin: 4px 0;
    display: block;
    color: $primary;
    font-weight: 300;
    text-decoration: none;
  }

  a.footerDetailsContent:hover {
    color: $baxus-gold;
  }
}

@media screen and (min-width: 1040px) {
  .footerTopSection {
    flex-direction: column;
    align-items: center;
    .footerLogo {
      margin-bottom: 24px;
    }
    .footerDetailsSection {
      padding-right: 24px;
    }
  }
}

.footerBottomSection {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #525252;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footerCopyright {
    font-size: 12px;
    color: $white;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
  .footerSocialSection {
    display: flex;
    .baxusSocialButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      color: $white;
      background-color: transparent;
      border: none;
    }
  }
}
