@import "../baxusStyles.scss";

/* Asset component styles */

.assetWrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  .assetBg {
    grid-row: 1 / span 2;
    grid-column: 1;
    background-color: $baxus-grey-dark;
    justify-self: center;
    height: 50%;
    width: 320px;
    margin-top: -116%;
    z-index: -1;
    box-shadow: 0px 33px 21px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 1280px) {
      margin-top: -120%;
    }
    @media screen and (min-width: 992px) and (max-width: 1024px) {
      margin-top: -103%;
      height: 45%;
    }
    @media screen and (min-width: 820px) and (max-width: 991px) {
      margin-top: -131%;
      height: 45%;
    }
    @media screen and (min-width: 698px) and (max-width: 768px) {
      margin-top: -86%;
    }
    @media screen and (min-width: 668px) and (max-width: 698px) {
      margin-top: -96%;
    }
    @media screen and (max-width: 575px) and (max-width: 668px) {
      margin-top: -105%;
    }
    @media screen and (min-width: 530px) and (max-width: 575px) {
      margin-top: -93%;
    }
    @media screen and (max-width: 529px) {
      margin-top: -113%;
    }
  }
  .assetClickableWrapper {
    display: grid;
    cursor: pointer;
    width: 100%;
    grid-row: 1;
    grid-column: 1;
    .assetImgWrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        height: 470px;
        width: 280px;
      }
    }
    .assetDetailsWrapper {
      .assetNameHeader {
        margin: 32px 0 0;
        font-weight: 600;
        min-height: 88px;
        text-align: center;
        font-size: 22px;
      }
      .assetDetailsArea {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        padding: 0 1rem 0;
        .assetDetailsAreaRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;

          p {
            font-weight: bold;
            margin: 0;
            font-size: 16px;
          }
        }
      }
      .assetDescription {
        margin-bottom: 6px;
        margin-top: 16px;
        font-weight: 300;
        min-height: 120px;
        font-size: 16px;
        text-align: center;
        padding: 0 1rem 0;
      }
    }
  }
  .assetPriceArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    grid-row: 2;
    grid-column: 1;
    padding: 0 1rem 0;

    h4 {
      margin: 0;
      font-size: 24px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .assetBtnCTA {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    .assetPurchaseCTA {
      justify-self: center;
      @include transparentButton();
      min-width: 180px;
      @media screen and (max-width: 768px) {
        width: 250px;
      }
    }
    .assetPurchaseCTA a:hover {
      background-color: #dd9c27;
    }
  }
}
