.searchBoxContent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding-left: 1.3rem;
  cursor: pointer;
}
