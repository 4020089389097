@import "../baxusStyles.scss";

.loaderWrapper {
  display: flex;
  justify-content: center;
  min-height: 600px;
}

figure {
  position: absolute;
  width: 0;
  height: 0;
  margin: -60px -50px;
  transform-style: preserve-3d;
  perspective: 600;

  span {
    display: block;
    position: absolute;
    transform-origin: 50% 100%;

    &:after {
      display: block;
      content: "";
      border-style: solid;
      border-color: transparent;
      border-width: 100px 58px 0;
      backface-visibility: hidden;
    }

    $time: 2s;
    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        transform: rotate3d(0, 0, 1, #{($i * 60deg) + 30deg});

        &:after {
          border-top-color: adjust-hue(#9a6d18, $i * 5);
          transform: rotate3d(0.5, 1, 0, 180deg);
          animation: split $time #{(0 + $i) * ($time/18)} ease infinite;
        }
      }
    }
  }
}

@keyframes split {
  0% {
    transform: -origin(50% 100%);
    transform: rotate3d(0.5, 1, 0, 180deg);
  }

  25% {
    transform: -origin(50% 100%);
    transform: rotate3d(0, 1, 0, 360deg);
  }

  50% {
    opacity: 1;
    transform: -origin(100% 0%);
    transform: rotate3d(0, 1, 0, 360deg);
  }

  75% {
    opacity: 0;
    transform: rotate3d(0.5, 1, 0, 180deg);
  }

  100% {
    opacity: 1;
    transform: (rotate3d(0.5, 1, 0, 180deg));
  }
}
